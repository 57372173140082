var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-start" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mb-3",
              attrs: { color: "primary" },
              on: {
                click: function () {
                  _vm.$router.go(-1)
                },
              },
            },
            [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c(
              "div",
              [
                _vm.api.isLoading
                  ? _c("v-skeleton-loader", {
                      ref: "skeleton",
                      attrs: { type: "table" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      this.subscriptionHistory != null
        ? _c(
            "v-card",
            {
              staticStyle: { "border-radius": "4px 4px 0px 0px" },
              attrs: { color: "secondary" },
            },
            [
              _c("div", { staticClass: "text-h5 pa-5 white--text" }, [
                _vm._v(" Subscription history "),
              ]),
            ]
          )
        : _vm._e(),
      _vm._l(this.subscriptionHistory, function (item, index) {
        return _c(
          "v-list-item",
          { key: index, staticClass: "px-0" },
          [
            _c(
              "v-container",
              { staticClass: "px-0 py-0" },
              [
                _c(
                  "v-card",
                  { staticStyle: { "border-radius": "0px 0px 0px 0px" } },
                  [
                    _c("div", { staticClass: "pa-4" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-start text-h6 mb-4" },
                        [_vm._v(" " + _vm._s(item.year) + " ")]
                      ),
                      _c("div", { staticClass: "d-flex justify-start" }, [
                        _vm._v(" Report date "),
                        _c("span", { staticClass: "font-weight-bold mx-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.convertTimeZone(item.created_at)) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "d-flex justify-start " }, [
                        _vm._v(" Year of subscription : "),
                        _c("span", { staticClass: "font-weight-bold mx-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.subscription_year != null
                                  ? item.subscription_year
                                  : "-"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "d-flex justify-start " }, [
                        _vm._v(" Status : "),
                        _c("span", { staticClass: "font-weight-bold mx-2" }, [
                          _vm._v(" " + _vm._s(item.status) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "d-flex justify-start" }, [
                        _vm._v(" Price (RM) : "),
                        _c("span", { staticClass: "font-weight-bold mx-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(item.price != null ? item.price : "-") +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "d-flex justify-start " }, [
                        _vm._v(" Payment date "),
                        _c("span", { staticClass: "font-weight-bold mx-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.payment_date != null
                                  ? _vm.convertTimeZone(item.payment_date)
                                  : "-"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "d-flex justify-start" }, [
                        _vm._v(" Subscription start date "),
                        _c("span", { staticClass: "font-weight-bold mx-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.subscription_start_date != null
                                  ? _vm.convertTimeZone(
                                      item.subscription_start_date
                                    )
                                  : "-"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "d-flex justify-start" }, [
                        _vm._v(" Subscription end date "),
                        _c("span", { staticClass: "font-weight-bold mx-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.subscription_end_date != null
                                  ? _vm.convertTimeZone(
                                      item.subscription_end_date
                                    )
                                  : "-"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "d-flex justify-start" }, [
                        _vm._v(" Commision release date "),
                        _c("span", { staticClass: "font-weight-bold mx-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.commision != null
                                  ? _vm.convertTimeZone(item.commision)
                                  : "-"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "d-flex justify-start" }, [
                        _vm._v(" Salesperson PIC : "),
                        _c("span", { staticClass: "font-weight-bold mx-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.pic_salesperson != null
                                  ? item.pic_salesperson
                                  : "-"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      item.cancellation_reason != null
                        ? _c("div", { staticClass: "d-flex justify-start" }, [
                            _vm._v(" Cancellation reason : "),
                            _c(
                              "span",
                              { staticClass: "font-weight-bold mx-2" },
                              [
                                _vm._v(
                                  " " + _vm._s(item.cancellation_reason) + " "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }